import httpClient from "../httpClient"
import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import {
    accessTypeFormat,
    updateAccessTypeFormat,
    accessTypeActivitiesFormat,
    accessTypeAccessFormat,
    accessTypeProviderFormat,
    registerFormat,
    saveProviderScheduleFormat,
    addActivitiesToAccessTypeFormat,
    changeAccessModePriorityFormat,
    activityBlacklistAndWhitelistFormat,
    createAccessTypeFormat,
    areasFormat,
    agenceFormat,
} from "../formats"

/**
 * Returns a list of accessType
 */
export function getAccessType(data) {
    data = formatData(accessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/accesstype", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAccessTypeActivities(accessTypeId, data) {
    data = formatData(accessTypeActivitiesFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/accesstype/${accessTypeId}/policy/activities`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getActivitySchedule(accessTypeId, activityId, data) {
    data = formatData(accessTypeAccessFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(
            `/accesstype/${accessTypeId}/policy/activities/${activityId}/schedule`,
            { params }
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export async function resetActivitySchedule(accessTypeId, activityId) {
    return await httpClient
        .delete(
            `/accesstype/${accessTypeId}/policy/activities/${activityId}/schedule`
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getProviderSchedule(accessTypeId, providerId, data) {
    data = formatData(accessTypeAccessFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(
            `/accesstype/${accessTypeId}/policy/providers/${providerId}/schedule`,
            { params }
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function resetProviderSchedule(accessTypeId, providerId) {
    return httpClient
        .delete(
            `/accesstype/${accessTypeId}/policy/providers/${providerId}/schedule`
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAccessTypeAccess(data) {
    data = formatData(accessTypeAccessFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/accesstype/access`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAccessTypeInfos(id) {
    return httpClient
        .get(`/accesstype/${id}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAccessTypeProviders(id, data) {
    data = formatData(accessTypeProviderFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/accesstype/${id}/policy/providers`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function saveProviderSchedule(accessTypeId, providerId, data) {
    data = formatData(saveProviderScheduleFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(
            `accesstype/${accessTypeId}/policy/providers/${providerId}/schedule`,
            data
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export async function saveActivitySchedule(accessTypeId, activityId, data) {
    data = formatData(saveProviderScheduleFormat, data)
    if (data === false) {
        throw "500"
    }
    return await httpClient
        .post(
            `accesstype/${accessTypeId}/policy/activities/${activityId}/schedule`,
            data
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function addActivitiesToAccessType(accessTypeId, data) {
    data = formatData(addActivitiesToAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${accessTypeId}/policy/activities`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function addActivitiesToProvider(accessTypeId, data) {
    data = formatData(addActivitiesToAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${accessTypeId}/policy/providers`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function createAccessType(data) {
    data = formatData(createAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function cloneAccessType(id, data) {
    data = formatData(createAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${id}/clone`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAreas(data) {
    data = formatData(areasFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/vigik3/areas`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
export function getAgences(data) {
    data = formatData(agenceFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/agence`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function deleteAccessType(id) {
    return httpClient
        .delete(`/accesstype/${id}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function updateAccessType(id, data) {
    data = formatData(updateAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${id}`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAccessTypeUpdate(id) {
    return httpClient
        .get(`/accesstype/${id}/origin`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function refuseAccessTypeUpdate(data, id) {
    data = formatData(updateAccessTypeFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${id}`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function changeAccessModePriority(id, data) {
    data = formatData(changeAccessModePriorityFormat, data)

    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${id}`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getProvidersWhitelisted(id, data) {
    data = formatData(activityBlacklistAndWhitelistFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/accesstype/${id}/policy/providers/whitelist`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getProvidersBlacklisted(id, data) {
    data = formatData(activityBlacklistAndWhitelistFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get(`/accesstype/${id}/policy/providers/blacklist`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function updateProvidersLevel(id, data) {
    data = formatData(activityBlacklistAndWhitelistFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/accesstype/${id}/policy/providers`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getActivitiesInfosProviders(id, providerId) {
    return httpClient
        .get(`/accesstype/${id}/policy/providers/${providerId}/activities`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
