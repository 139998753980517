<template>
    <div class="breadcrumb-container">
        <div v-for="(item, index) in store" :key="item.id">
            <div
                class="breadcrumb-item"
                :class="{ 'no-hover': index === store.length - 1 }"
                @click="index === store.length - 1 ? null : goTo(item.link)"
            >
                <i
                    class="fas fa-angle-right"
                    v-if="index !== 0"
                    :style="
                        index !== store.length - 1 ? null : 'color: #ef7b1d'
                    "
                ></i>
                <span
                    :style="
                        index !== store.length - 1 ? null : 'color: #ef7b1d'
                    "
                    >{{ item.name }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VigikBreadCrumb",
    mounted() {
        this.keepPath()
    },
    watch: {
        "$route.path"() {
            this.keepPath()
        },
    },
    data() {
        return {
            store: [],
        }
    },
    methods: {
        keepPath() {
            // Créer une copie profonde du store pour ne pas le modifier directement
            this.store = JSON.parse(
                JSON.stringify(
                    this.$store.getters["breadCrumb/getVigikBreadCrumb"]
                )
            )

            let newObject = {
                name: null,
                link: this.$route.path,
                routeName: this.$route.name,
            }

            const params = this.$route.params

            for (let key in params) {
                if (key.endsWith("Name") || key.endsWith("name")) {
                    newObject.name = params[key]
                }
            }

            let index = this.store.findIndex(
                (item) => item.routeName === newObject.routeName
            )

            if (index !== -1) {
                this.store.length = index // Cela réduit la taille du tableau sans mutation directe des objets
            }

            if (newObject.name) {
                this.store.push(newObject)
            }

            this.$store.commit("breadCrumb/setVigikBreadCrumb", this.store)
        },

        goTo(link) {
            this.$router.push(link)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.breadcrumb-container {
    display: flex;
    flex-wrap: wrap;
    text-wrap: nowrap;
    align-items: center;
    gap: 0.5em;

    .breadcrumb-item {
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: $normal;
        transition: 0.15s all;

        &:hover {
            cursor: pointer;
            color: $orange-neutral;
            transition: 0.15s all;
        }

        &::before {
            display: none;
        }
    }
}

.no-hover {
    cursor: default !important;
}
</style>
