<template>
    <div class="activity-providers">
        <div class="block-list" v-if="!loading">
            <div class="top-block">
                <div class="return_page">
                    <VigikBreadCrumb />
                </div>
                <div class="title-and-search">
                    <div class="title">
                        <h1>
                            {{ $route.params.activityName }}
                        </h1>
                        <BasicChip
                            class="chip"
                            :text="
                                $route.params.activityStatus === '1'
                                    ? $t('vigik.global.activitiesAllows')
                                    : $t('vigik.global.activitiesBanned')
                            "
                            :background="
                                $route.params.activityStatus === '1'
                                    ? 'blue-powder'
                                    : 'dark-blue'
                            "
                        />
                    </div>
                    <basic-search
                        class="search"
                        :searchPlaceholder="$t('vigik.provider.search')"
                        @search="searchProviders"
                    />
                </div>
            </div>
            <div class="middle-block">
                <div class="custom-access-type-array" v-if="!tabLoader">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    {{ $t("vigik.provider.activity") }}
                                </th>
                                <th>
                                    {{ $t("vigik.custom.status") }}
                                </th>
                                <th class="number">
                                    {{ $t("vigik.custom.providerTimeRange") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="providers.length > 0">
                            <tr
                                v-for="(provider, index) in providers"
                                :key="`activity-table-${index}`"
                            >
                                <td>
                                    {{ provider.company }}
                                </td>
                                <td>
                                    <BasicChip
                                        :text="
                                            provider.level === 'SELECTED' ||
                                            !provider.level
                                                ? $t('vigik.global.default')
                                                : provider.level === 'WHITELIST'
                                                ? $t(
                                                      'vigik.global.whitelisted',
                                                      {
                                                          count: 0,
                                                      }
                                                  )
                                                : provider.level === 'BLACKLIST'
                                                ? $t(
                                                      'vigik.global.blacklisted',
                                                      {
                                                          count: 0,
                                                      }
                                                  )
                                                : null
                                        "
                                        :pinColor="
                                            provider.level === 'SELECTED' ||
                                            !provider.level
                                                ? 'blue-teal'
                                                : provider.level === 'WHITELIST'
                                                ? 'orange'
                                                : provider.level === 'BLACKLIST'
                                                ? 'blue'
                                                : null
                                        "
                                        font="roman"
                                    />
                                </td>
                                <td class="check">
                                    <span>
                                        <Tooltip
                                            :text="
                                                $t(
                                                    'vigik.custom.timetablesCustomised'
                                                )
                                            "
                                            v-if="
                                                provider.schedule_id &&
                                                provider.level === 'WHITELIST'
                                            "
                                        >
                                            <i
                                                @click="
                                                    providerSchedule(provider)
                                                "
                                                class="fas fa-clock"
                                                style="color: #ef7b1dff"
                                            ></i>
                                        </Tooltip>
                                        <Tooltip
                                            :text="
                                                $t(
                                                    'vigik.custom.timetablesDefault'
                                                )
                                            "
                                            v-else-if="
                                                provider.level === 'SELECTED' ||
                                                !provider.level ||
                                                (provider.level !==
                                                    'BLACKLIST' &&
                                                    !provider.schedule_id)
                                            "
                                        >
                                            <i
                                                @click="
                                                    providerSchedule(provider)
                                                "
                                                class="fas fa-pen"
                                                style="color: #53a6bb"
                                            ></i>
                                        </Tooltip>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody
                            v-else-if="isSearchText"
                            class="no-providers"
                            colspan="3"
                        >
                            <tr>
                                <td colspan="3">
                                    {{ $t("global.array.noSearchData") }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else class="no-providers" colspan="3">
                            <tr>
                                <td colspan="3">
                                    {{ $t("global.array.emptyList") }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination
                        v-show="pagesCount > 1"
                        :pagesCount="pagesCount"
                        :page="currentPage"
                        v-on:new-current-page="(e) => (this.currentPage = e)"
                    >
                    </pagination>
                </div>
                <div v-else class="block-loader">
                    <loader />
                </div>
            </div>
        </div>
        <div class="block-loader" v-if="loading">
            <Loader />
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/basic/Pagination"
import BasicSearch from "@/components/basic/BasicSearch.vue"
import Loader from "@/components/basic/Loader.vue"
import BasicChip from "@/components/basic/BasicChip.vue"
import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"

import {
    addActivitiesToProvider,
    getAccessTypeProviders,
} from "@/services/intratone/accesstype"
import { useToast } from "vue-toastification"
import Tooltip from "@/components/basic/Tooltip.vue"

const toast = useToast()

export default {
    name: "ActivityProvider",
    components: {
        Pagination,
        BasicSearch,
        Loader,
        VigikBreadCrumb,
        Tooltip,
        BasicChip,
    },
    data() {
        return {
            currentPage: 1,
            pagesCount: 0,
            providers: [],
            initialArray: [],
            loading: true,
            isSearchText: false,
            tabLoader: false,
        }
    },
    mounted() {
        this.loading = true
        this.getData()
        this.loading = false
    },
    methods: {
        async getData() {
            this.tabLoader = true
            this.isSearchText = false
            this.providers = []
            await getAccessTypeProviders(this.$route.params.accessId, {
                page: this.currentPage,
                activity_id: this.$route.params.activityId,
            }).then((response) => {
                response.data.list.forEach((e) => {
                    this.providers.push({
                        id: e.id,
                        code: e.code,
                        company: e.company,
                        schedule_id: e.schedule_id,
                        level: e.level,
                        allow:
                            e.level === "WHITELIST" || e.level === "SELECTED",
                    })
                    this.initialArray.push({
                        id: e.id,
                        allow:
                            e.level === "WHITELIST" || e.level === "SELECTED",
                    })
                })
                this.pagesCount = response.data._pages
            })
            this.tabLoader = false
        },
        goToCustomAccessType() {
            this.$router.go(-1)
        },
        providerSchedule(provider) {
            const add = this.checkAddAndDel().add
            const del = this.checkAddAndDel().del

            const even = (element) => element === provider.id

            if (!del.some(even)) {
                this.$router.push({
                    name: "ProviderSchedule",
                    params: {
                        accessId: this.$route.params.accessId,
                        providerId: provider.id,
                        providerName: provider.company,
                        activityId: this.$route.params.activityId,
                        activityName: this.$route.params.activityName,
                        scheduleId: provider.schedule_id ?? 0,
                        isPersonnalised:
                            provider.level === "SELECTED" || !provider.level
                                ? "0"
                                : provider.level === "WHITELIST"
                                ? "1"
                                : provider.level === "BLACKLIST"
                                ? "2"
                                : null,
                    },
                })
            }
        },
        checkAddAndDel() {
            const startAdd = []
            const startDel = []

            this.providers.forEach((provider, index) => {
                this.initialArray.forEach((e, i) => {
                    if (
                        e.id === provider.id &&
                        e.allow === false &&
                        provider.allow === true
                    ) {
                        startAdd.push(provider.id)
                    } else if (
                        e.id === provider.id &&
                        e.allow === true &&
                        provider.allow === false
                    ) {
                        startDel.push(provider.id)
                    }
                })
            })

            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }

            const del = startDel.filter(unique)
            const add = startAdd.filter(unique)

            return {
                add: add,
                del: del,
            }
        },
        async saveProviders() {
            const add = this.checkAddAndDel().add
            const del = this.checkAddAndDel().del

            await addActivitiesToProvider(this.$route.params.accessId, {
                add,
                del,
            })

            toast.success(
                this.$t("vigik.access.update-access-check", {
                    name: this.$route.params.activityName,
                }),
                {
                    icon: "fas fa-check",
                }
            )
        },
        async searchProviders(value) {
            this.tabLoader = true
            this.isSearchText = true
            if (value) {
                await getAccessTypeProviders(this.$route.params.accessId, {
                    page: this.currentPage,
                    activity_id: this.$route.params.activityId,
                    q: value,
                }).then((response) => {
                    this.providers = []
                    response.data.list.forEach((e) => {
                        this.providers.push({
                            id: e.id,
                            code: e.code,
                            company: e.company,
                            schedule_id: e.schedule_id,
                            level: e.level,
                            allow:
                                e.level === "WHITELIST" ||
                                e.level === "SELECTED",
                            initialAllow:
                                e.level === "WHITELIST" ||
                                e.level === "SELECTED",
                        })
                        this.initialArray.push({
                            id: e.id,
                            allow:
                                e.level === "WHITELIST" ||
                                e.level === "SELECTED",
                        })
                    })
                    this.pagesCount = response.data._pages
                })
            } else {
                await this.getData()
            }

            this.tabLoader = false
        },
    },
    beforeRouteLeave(to, from, next) {
        const add = this.checkAddAndDel().add
        const del = this.checkAddAndDel().del

        if (del.length > 0 || add.length > 0) {
            this.openModal("validation", {
                title: "valid.edit",
                valid: async () => {
                    await this.saveProviders()
                    next()
                },
                refuse: () => {
                    next()
                },
            })
        } else {
            next()
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}
.activity-providers {
    width: 100vw;
    height: 100%;
    overflow: auto;
    position: relative;

    @media all and (max-width: 768px) {
        padding-left: 20px;
    }
    @media all and (min-width: 768px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    .return_page {
        padding-top: 10px;
        height: 4%;
        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }
    .block-list {
        margin-top: 10px;
        width: 100%;
        overflow: visible;
        display: flex;
        flex-direction: column;
        gap: 2em;

        .top-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: flex-start;
            gap: 0.5em;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgb(255, 255, 255) 90%,
                rgba(255, 255, 255, 0)
            );

            @media all and (max-width: 768px) {
                padding-right: 20px;
            }

            .title-and-search {
                display: flex;
                flex-direction: column;
                gap: 2em;

                @media all and (max-width: 768px) {
                    gap: 1.5em;
                    flex-direction: column;
                    align-items: start;
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    @media all and (max-width: 768px) {
                        flex-direction: column;
                        align-items: start;
                        gap: 0.2em;
                    }
                    h1 {
                        font-family: $font_avenir_black;
                        margin: 0;
                        font-size: $veryBig;
                    }
                }

                .chip {
                    font-family: $font_avenir_heavy;
                }

                .search {
                    font-size: $normal;

                    @media all and (max-width: 768px) {
                        width: 70%;
                    }

                    @media all and (max-width: 480px) {
                        width: 100%;
                    }
                }
            }
        }
        .middle-block {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            overflow-x: auto;

            @media all and (max-width: 768px) {
                padding-right: 20px;
            }
            .custom-access-type-array {
                width: 100%;
                margin: 0;
                min-width: max-content;
                overflow-x: auto;

                table {
                    width: 100%;
                    thead {
                        border: none;
                        color: $blue-neutral;
                        tr {
                            border-bottom: solid 1px $grey-neutral;
                            th {
                                font-size: $normal;
                                font-family: $font_avenir_heavy;
                                width: 25%;
                                max-width: 400px;
                                padding: 0.7em 1em;

                                @media all and (max-width: 768px) {
                                    width: 100%;
                                    max-width: 100%;
                                    white-space: nowrap;
                                }
                                &.number {
                                    text-align: start;

                                    @media all and (max-width: 768px) {
                                        width: 100%;
                                        max-width: 100%;
                                        min-width: max-content;
                                    }
                                }
                                &.check {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    text-align: start;
                                    gap: 5px;

                                    @media all and (max-width: 768px) {
                                        width: 100%;
                                        max-width: 100%;
                                        min-width: max-content;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        color: $blue-dark;
                        font-size: $small;
                        font-family: "Avenir Heavy";
                        &.no-providers {
                            cursor: default;

                            tr {
                                border-bottom: 0px solid $grey-silver !important;
                                &:hover {
                                    background-color: $white;
                                }
                                td {
                                    color: $pink;
                                    font-family: $font_avenir_heavy;
                                    border-bottom: 0px solid $grey-silver !important;
                                    text-align: center;
                                }
                            }
                        }
                        tr {
                            &.add {
                                font-size: $normal;
                                color: $orange-neutral;
                                cursor: pointer;
                            }
                            background-color: $white;
                            transition: background-color 200ms;
                            height: 40px;
                            border-bottom: solid 1px $grey-neutral;
                            td {
                                vertical-align: middle;
                                padding: 1em 1em;
                                font-family: $font-avenir-roman;
                                i {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                                svg {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                                &.number {
                                    font-size: $normal;
                                    text-align: start;
                                    i {
                                        cursor: default;
                                    }
                                }
                                &.check {
                                    font-size: $normal;
                                    text-align: start;
                                }
                            }
                            &:hover {
                                background-color: $grey-light;
                            }
                        }
                        border: none !important;
                    }
                }
            }
            .legende {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-content: center;
                align-items: center;
                gap: 20px;
                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-content: center;
                    align-items: center;
                    gap: 5px;
                }
            }
        }
        .bottom-block {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-wrap: nowrap;
            gap: 20px;
            margin-bottom: 20px;
            .btn-style {
                padding: 20px;
            }
        }
        .top-block {
            @media all and (max-width: 768px) {
                .title-and-search {
                    font-size: $big;
                }
            }
        }
    }
    .block-loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.btn {
    @media all and (max-width: 768px) {
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
</style>
